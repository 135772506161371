import React, {useEffect, useState} from 'react';

import './KRMListBox.css';
import { ListBox } from 'primereact/listbox';


export const KRMListBox = (props) => {
    const [values, setValues] = useState(props?.options?.find(obj => obj.code === props?.value?.[0]));

    useEffect(() => {
        setValues(props?.options?.find(obj => obj.code === props?.value?.[0]));
    }, [props?.options])


    const onValueChanged = (value)  =>{
        setValues(value);
        props?.onChange?.([value?.code]);
    }

    return (
        <div className={`krm-list-box-wrapper ${props?.wrapperClassName ?? ''}`}>
            <div className='krm-list-box-label'>{props.label}</div>
            <ListBox id={props.id} emptyMessage={props.emptyMessage || 'Data is empty'} options={props?.options} optionLabel="name" 
                value={values} onChange={(e) => onValueChanged(e.value)} 
                className={`krm-list-box-list ${props.className || ''} ${props.disabled ? 'krm-list-box-list-disabled' : ''}` }
            />
        </div>
    )
}

export default KRMListBox;