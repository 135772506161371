import { jwtDecode } from 'jwt-decode' 

function getToken() {
  const userToken = localStorage.getItem("user_info");
  if (!userToken)
    return null
  const parsedToken = JSON.parse(userToken)
  return parsedToken.token
}

function getValueFromToken(value, defaultValue) {
  const token = getToken()
  if (!token) {
    return defaultValue
  }

  const expirationTime = jwtDecode(token).exp;

  if (expirationTime * 1000 < Date.now()) {
    return defaultValue
  }

  const res = jwtDecode(token)[value]
  return res
}

async function parseAPK(fileName) {
  try {
    const parser = new window.AppInfoParser(fileName);
    const result = await parser.parse();
    return result;
  } catch(err) {
    console.error('Error:', err);
  }
}


export {getToken, getValueFromToken, parseAPK}; 