import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { apiAddVersion, apiGetProfiles } from '../../../APIService';
import { KRMListBox } from "../../../components/KRMListBox/KRMListBox";
import { InputText } from 'primereact/inputtext';
import { InputNumber } from "primereact/inputnumber";
import { parseAPK } from '../../../utils/utils';

import '../AddApplication.css'

const AddAVersion = (props) => {
  const [apkName, setApkName] = useState(props.selectedVersion == null ? '' : props.selectedVersion.name)
  const [apk, setApk] = useState(null)
  const [apkError, setApkError] = useState('')

  const [packageName, setPackageName] = useState(props.selectedVersion == null ? '' : props.selectedVersion?.package_name)
  const [packageError, setPackageError] = useState('')

  const [loading, setLoading] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [selProfile, setSelProfile] = useState(props.selectedVersion?.profile?.map(item => {
                                                return item?.id}))
 
  const [version, setVersion] = useState(props.selectedVersion == null ? '' : props.selectedVersion?.version)
  const [versionError, setVersionError] = useState('')

  const [versionCode, setVersionCode] = useState(props.selectedVersion == null ? null : props.selectedVersion?.version_code)
  const [versionCodeError, setVersionCodeError] = useState('')

  const apkInputRef = useRef(null);
  const toast = useRef(props.refToast)

  const getProfiles = () => {
    apiGetProfiles().then(response => {
      const items = response.map((item) => {
        return {name: item.serial, code: item.profile_id}
      })
      items.push({name: 'Общий профиль', code: null}) 
      setProfiles(items)
    })
  }

  useEffect(() => {
    getProfiles()
  }, []);


  async function onAdd() {
    if (!validate()) {
      return;
    }

    setLoading(true)
    let isAdd = (props.selectedVersion == null)

    const formData = new FormData();
   
    if (isAdd) {
      formData.append('app_id', props.selectedApp.app_id);
    }

    formData.append('apk_name', apkName);
      
    if (!isAdd) {
      formData.append('id', props.selectedVersion.id);
    }

    formData.append("apk", apk);
    formData.append("profiles", selProfile?.map(item => item === null ? "null" : item).join(","));
    formData.append("package_name", packageName);
    formData.append("version", version);
    formData.append("version_code", versionCode);
    

    const result = await apiAddVersion(formData)
    if (result.result) {
      toast.current.current.show({ severity: 'success', summary: 'Успешно', detail: `${isAdd ? 'Версия добавлена' : 'Изменения сохранены'}`, life: 3000 })
      props.onRefresh()
    }
    else {
      const errorText = result?.errorText ?? 'Изменения не сохранены' 
      toast.current.current.show({ severity: 'error', summary: 'Ошибка', detail: errorText, life: 3000 })
    }

    setLoading(false)
  }

  const onProfileChange = (val) => {
    setSelProfile(val);
  }

  const apkBtnClick = () => {
    apkInputRef.current.click();
  }

  const onApkChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile !== undefined) {
      setLoading(true)
      parseAPK(selectedFile).then((result) => {
        if (result) {
          onPackageNameChange(result?.package);
          onVersionChange(result?.versionName)
          onVersionCodeChange(result?.versionCode)

        }
        else {
          setPackageName('')
          onVersionChange('')
          onVersionCodeChange('')
          toast.current.current.show({ severity: 'warn', summary: 'Не удалось получить информацию из файла', detail: 'Имя пакета, версия и код версии не найдены', life: 3000 })
        }
        setLoading(false)
      })
      setApk(selectedFile)
      setApkName(selectedFile.name)
      setApkError('')
    }
  }

  const onVersionChange = (e) => {
    const value = e.hasOwnProperty('target') ? e?.target?.value : e;
    setVersion(value)
    setVersionError('')
  }

  const onVersionCodeChange = (e) => {
    const value = e.hasOwnProperty('value') ? e?.value : e;
    setVersionCode(value)
    setVersionCodeError('')
  }

  const onPackageNameChange = (e) => {
    const value = e.hasOwnProperty('target') ? e?.target?.value : e;
   
    setPackageName(value)
    setPackageError('')
  }



  const validate = () => {
    let isValid = true

    if (apkName === '') {
      setApkError('Добавьте apk-файл')
      isValid = false
    }

    if (packageName === '') {
      setPackageError('Заполните имя пакета')
      isValid = false
    }

    if (version === '') {
      setVersionError('Заполните версию')
      isValid = false
    }

    if (versionCode === '') {
      setVersionCodeError('Заполните код версии')
      isValid = false
    }

    return isValid;
  }


  const footer = () => {
    return (
      <div>
        <Button label="Отмена" icon="pi pi-times" onClick={props.onHide} className="p-button-text" />
        <Button disabled={loading} label='Сохранить' icon="pi pi-check" onClick={onAdd} autoFocus />
      </div>
    );
  }

  return (
    <div>
      <Dialog visible={props.visible} onHide={props.onHide} 
        className={'add-version-dialog'} headerClassName='add-dialog-header' footer={footer} footerClassName='add-dialog-footer'
        header={props.selectedVersion == null ? 'Добавить версию' : 'Редактировать версию'}> 
        <div className="add-container krm-add-version">
          {loading && 
            <div className="add-container-loading">
              <ProgressSpinner strokeWidth="3" style={{ width: '50px', height: '50px' }}/>
            </div>
          }
          <span className="field-add">
            <span className='add-apk'>
              <Button disabled={loading} onClick={apkBtnClick} icon = {`pi pi-fw ${apkName === '' ? 'pi-plus' : 'pi-pencil'}`} label={apkName === '' ? 'Добавить apk-файл' : `Заменить ${apkName}`}></Button>
              <input type="file" ref={apkInputRef} accept=".apk, .zip" style={{ display: 'none' }} onChange={onApkChange}/>
            </span>              
            <small id="apk-name-error" className="p-error">{apkError}</small>
          </span>
          <span className="field-add">
            <span className='add-apk'>
              <label htmlFor="name">Имя пакета</label>
              <InputText id="package-name" value={packageName} onChange={onPackageNameChange} disabled={loading}/>
            </span>
            <small id="package-error" className="p-error">{packageError}</small>
          </span>
          <span className="field-add">
            <span className="field-add">
              <label htmlFor="version">Версия</label>
              <InputText id="version" value={version} onChange={onVersionChange} className="block" disabled={loading}/>
            </span>
            <small id="version-error" className="p-error">{versionError}</small>
          </span>
          <span className="field-add">
            <span className="field-add">
              <label htmlFor="version-code">Код версии</label>
              <InputNumber id="version-code" value={versionCode} onChange={onVersionCodeChange} className="block" 
                disabled={loading} mode="decimal" useGrouping={false} min={0} max={9999999999}/>
            </span>
            <small id="version-code-error" className="p-error">{versionCodeError}</small>
          </span>
          <KRMListBox options={profiles} value={selProfile} showAllLabel={false} label='Профили' onChange={onProfileChange} disabled={loading}/>
        </div>
      
      </Dialog>
    </div>      
  )
}

export default AddAVersion